<template>
    <vx-card title="Buying Price Approval">
        <div class="vx-row mt-3" style="width: 70%">
            <div class="vx-col w-full mb-base">
                <FilterData :multiSp="true" :multiWh="true" @data="updateFilterData"></FilterData>
            </div>
        </div>
        <div class="flex vx-input-group">
            <vs-tabs>
                <vs-tab label="Waiting">
                    <tab-waiting :filterData="filterData"></tab-waiting>
                </vs-tab>
                <vs-tab label="Approved">
                    <tab-approved :filterData="filterData"></tab-approved>
                </vs-tab>
                <vs-tab label="Rejected">
                    <tab-rejected :filterData="filterData"></tab-rejected>
                </vs-tab>
            </vs-tabs>
        </div>
    </vx-card>
</template>
<script>
import TabApproved from "./TabApproved.vue";
import TabRejected from "./TabRejected.vue";
import TabWaiting from "./TabWaiting.vue";
import FilterData from "./component/FilterData.vue";
export default {
    components: {
        TabApproved,
        TabRejected,
        TabWaiting,
        FilterData,
    },
    data() {
        return {
            activeTab: "Waiting",
            filterData: {
                warehouseId: null,
                supplierId: null,
                draw: 0
            }
        }
    },
    methods: {
        updateFilterData(val) {
            this.filterData.warehouseId = val.warehouseId;
            this.filterData.supplierId = val.supplierId;
            this.filterData.draw = val.draw;
        },
    }
}
</script>